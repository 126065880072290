import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { categoryGetAll, objectiveGetAll, priorityGetAll } from '../../services/ApiRequestService';
import { convertFromDateString } from '../../services/DateService';
import { TCategory } from '../../types/TCategory';
import { TObjective } from '../../types/TObjective';
import { TPriority } from '../../types/TPriority';

const Objectives = () => {

    const [objectives, setObjectives] = useState<TObjective[]>([]);
    const [categories, setCategories] = useState<TCategory[]>([]);
    const [priorities, setPriorities] = useState<TPriority[]>([]);

    //filters
    const [filterCategoryId, setFilterCategoryId] = useState<number>(0);
    const [filterPriorityId, setFilterPriorityId] = useState<number>(0);
    const [filterStartDate, setFilterStartDate] = useState<string>("");
    const [filterEndDate, setFilterEndDate] = useState<string>("");
    const [filterName, setFilterName] = useState<string>("");

    React.useEffect(() => {
        objectiveGetAll().then(
            (objectives) => {
                setObjectives(objectives);

                categoryGetAll().then((categories) => {
                    setCategories(categories.sort((c1, c2) => c1.name.localeCompare(c2.name)));
                });

                priorityGetAll().then((priorities) => {
                    setPriorities(priorities.sort(p => p.number));
                });
            }
        );

        return () => {
            //alert('Will unmount');
        };
    }, []);

    React.useEffect(() => {
    }, [filterCategoryId, filterPriorityId, filterStartDate, filterEndDate, filterName]);

    const ResetFilters = () => {
        setFilterCategoryId(0);
        setFilterPriorityId(0);
        setFilterStartDate("");
        setFilterEndDate("");
        setFilterName("");
    }

    const ContainsTexts = (name: string, description: string, filterValue: string): boolean => {
        let filterLower = filterValue.toLowerCase();

        if (name !== null && name.toLowerCase().includes(filterLower)) {
            return true;
        }

        if (description !== null && description.toLowerCase().includes(filterLower)) {
            return true;
        }

        return false;
    }

    return (
        <React.Fragment>
            <h1>List of tasks</h1>
            <Link to='/objective/edit/new'>
                <Button type='button'>new objective</Button>
            </Link>
            <h1></h1>

            <div className="row">
                <div className="col-4">
                    <input type="text" value={filterName} onChange={(e) => setFilterName(e.target.value)} />
                </div>
                <div className="col-4">
                    <p><input type="date" value={filterStartDate} onChange={(e) => setFilterStartDate(e.target.value)} /> - 
                        <input type="date" value={filterEndDate} onChange={(e) => setFilterEndDate(e.target.value)} /></p>
                </div>
                <div className="col">
                    <select onChange={(e) => setFilterPriorityId(Number(e.target.value))}>
                        <option value={0} ></option>
                        {priorities.map((priority, i) => {
                            return (<option key={i} value={priority.id} >{priority.name}</option>)
                        })}
                    </select>
                </div>
                <div className="col">
                    <select onChange={(e) => setFilterCategoryId(Number(e.target.value))}>
                        <option value={0} ></option>
                        {categories.map((category, i) => {
                            return (<option key={i} value={category.id} >{category.name}</option>)
                        })}
                    </select>
                </div>
                <div className="col-2">
                    {/*<Button type='button' onClick={ResetFilters}>Reset</Button>*/}
                </div>
            </div>
            {objectives.map((objective, i) => {
                let startDate = convertFromDateString(objective.startTime);
                let endDate = convertFromDateString(objective.endTime);

                if (filterCategoryId > 0) {
                    if (objective.categoryId !== filterCategoryId) {
                        return;
                    }
                }

                if (filterPriorityId > 0) {
                    if (objective.priorityId !== filterPriorityId) {
                        return;
                    }
                }

                let start = convertFromDateString(objective.startTime);
                let end = convertFromDateString(objective.endTime);

                if (filterStartDate !== "") {
                    let filterStart = convertFromDateString(filterStartDate);                    

                    if (end < filterStart)
                        return;
                }

                if (filterEndDate !== "") {
                    let filterEnd = convertFromDateString(filterEndDate);                    

                    if (filterEnd < start)
                        return;
                }

                if (filterName !== "") {
                    if (!ContainsTexts(objective.name, objective.description, filterName)) {
                        return;
                    } 
                }

                let name = objective.name;
                const today = new Date();
                today.setDate(today.getDate());

                if (end <= today) {
                    name = "!!! " + name;
                } 
                else if (start <= today) {
                    name = "! " + name
                }

                return (
                    <div className="row">
                        <div className="col-5">
                            {objective.name}
                        </div>
                        <div className="col-3">
                            {startDate.toLocaleDateString()} - {endDate.toLocaleDateString()} 
                        </div>
                        <div className="col">
                            {priorities.find(f => f.id == objective.priorityId)?.name} 
                        </div>
                        <div className="col">
                            {categories.find(f => f.id == objective.categoryId)?.name}
                        </div>
                        <div className="col">
                            <Link to={'/objective/' + objective.id}>
                                <Button type='button'>show</Button>
                            </Link>
                        </div>
                        <div className="col">
                            <Link to={'/objective/edit/' + objective.id}>
                                <Button type='button'>edit</Button>
                            </Link>
                        </div>
                    </div>)
            })}            
        </React.Fragment>
    );
};

export default Objectives